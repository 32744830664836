<template>
  <section class="auto-parking-assist-warpper pb50">
    <div class="banner">
      <ImageWithPlaceholder
        src="/image/apa/apabanner.png"
        alt="Auto Parking Assist (APA)"
      />
      <div class="auto-parking-assist-box">
        <h1 class="auto-parking-assist-title">Auto Parking Assist (APA)</h1>
        <div class="item-list">
          <a href="#avm" class="avm">AVM</a>
          <a href="#apa" class="apa">APA</a>
          <a href="#hpa" class="hpa">HPA</a>
          <a href="#sentry-mode" class="sentry-mode">Sentry Mode</a>
          <a href="#smart-trunk" class="smart-trunk">Smart Trunk</a>
        </div>
      </div>
    </div>
    <div id="avm" class="content avm-warpper pt120">
      <h2 class="avm-title h2-title">Around View Monitoring (AVM)</h2>
      <p class="p-text avm-text">
        ArcSoft AVM products support 360° surrounding view plus 180° underneath
        view, combined to form a 540° bird’s eye view (BEV) of the surrounding
        area. Based on the high degree cross-platform adaptations, ArcSoft 540°
        panoramic in-vehicle solution is achieved through deep integration and
        optimization with the platforms for successful reductions of CPU, GPU,
        and other resource consumptions.
      </p>
      <p class="p-text avm-text">
        ArcSoft AVM products support the following functions:
      </p>
      <p class="p-text right">
        Seamless stitching with color balancing and distortion correction;
      </p>
      <p class="p-text right">
        2D aerial, 2D single view, and wide-angle view;
      </p>
      <p class="p-text right">
        Normal 3D view, 3D transparent chassis view, and 3D front or rear trunk
        view;
      </p>
      <p class="p-text right">
        Corner object indicator and blind spot following;
      </p>
      <p class="p-text right">
        Self calibration, and adaptive multi-pattern calibration.
      </p>
    </div>
    <div class="avm-supports-warpper mt50">
      <div class="content avm-supports-bg">
        <div class="supports supports-box">Supports</div>
        <h3 class="soc-platforms-white h3-title mt50">SoC platforms</h3>
        <p class="p-text">
          Qualcomm (SA8155P, SA8295P, SA8775P, SM8475, SM7325, SA8620P, SA8650P
          etc)
        </p>
        <p class="p-text">MTK (MT8666, MT2712, MT8678 etc)</p>
        <p class="p-text">Siengine (SE1000 etc)</p>
        <p class="p-text">TI (TDA4 etc)</p>

        <h3 class="operating-systems-white h3-title mt20">Operating Systems</h3>
        <p class="p-text">Android, Linux, QNX.</p>
        <h3 class="camera-locations-white h3-title mt20">Camera locations</h3>
        <p class="p-text">
          Around the body, at the front, rear, left and right.
        </p>
        <h3 class="sensors-white h3-title mt20">Sensors</h3>
        <p class="p-text">Support RGB sensors from 1M up.</p>
      </div>
    </div>
    <div class="apa-warpper">
      <div class="content">
        <h2 id="apa" class="apa-title h2-title pt80">
          Auto-Parking Assist (APA)
        </h2>
        <p class="p-text apa-text mt30">
          ArcSoft Auto-Parking Assist (APA) system is a cost-effective APA
          solution for the OEMs that utilizes existing computing resources on a
          single IVI or ADAS SoC and the AVM/APA cameras.
        </p>
        <p class="p-text apa-text">
          Using the existing AVM cameras, ArcSoft APA perception algorithms
          detect precise object locations and free parking spaces. Based on the
          perception results, ArcSoft APA solutions provide stable planning and
          control capabilities that can handle complex parking scenarios such as
          dead-end roads and multi-layer mechanical parking spaces, with robust
          performance. ArcSoft APA solutions adapt to various environmental
          elements, including parking spaces, corner parking spots, adjacent
          vehicles, cones, sidewalks, barriers, walls, pillars, curbs, and more.
        </p>
        <p class="parking-positions mt30">6+ parking configurations</p>
        <div class="apa-list">
          <img src="/image/apa/apa-list.png" />
        </div>
        <div class="apa-supports-warpper mt80 pb50">
          <div class="supports supports-box">Supports</div>
          <h3 class="soc-platforms-black h3-title mt50">SoC platforms</h3>
          <p class="p-text">TI (TDA4 etc)</p>
          <p class="p-text">
            Qualcomm (SA8155P, SA8255P, SA8295P, SA8775P, SA8620P, SA8650P etc)
          </p>
          <h3 class="operating-systems-black h3-title mt20">
            Operating Systems
          </h3>
          <p class="p-text">Linux, QNX.</p>
          <h3 class="camera-locations-black h3-title mt20">Camera locations</h3>
          <p class="p-text">
            Around the body, at the front, rear, left and right.
          </p>
          <h3 class="sensors-black h3-title mt20">Sensors</h3>
          <p class="p-text">Support RGB sensors from 1.5M up.</p>
        </div>
      </div>
      <div class="apa-bg"></div>
    </div>
    <div id="hpa" class="hpa-warpper pt80">
      <div class="content">
        <h2 class="hpa-title h2-title">Home-zone Parking Assist (HPA)</h2>
        <p class="p-text mt30">
          ArcSoft Home-zone Parking Assist (HPA) is an extension feature of APA.
        </p>
        <p class="p-text">
          Similar to the APA system, but with an additional front facing ADAS
          camera, ArcSoft Home-zone Parking Assist (HPA) system is also a
          cost-effective solution for the OEMs that utilizes existing computing
          resources on a single IVI or ADAS SoC, the front facing ADAS camera,
          and the AVM/APA cameras.
        </p>
        <p class="p-text">
          Many people enter and exit the same parking places repetitly every
          day. ArcSoft HPA helps to automate this task without the driver's
          intervention. The driver can activate the learn-to-park process and
          drives the car to the desired parking spot once. The HPA system
          remembers the route, with proper start point and end point. When the
          car reaches the start position again, the HPA system takes on the
          parking maneuver into the proper parking space without the driver.
          ArcSoft HPA can adapt to various environmental elements, including
          parking spaces, corner parking spots, adjacent vehicles, cones,
          sidewalks, barriers, walls, pillars, curbs, and more.
        </p>
      </div>
      <div class="hpa-bottom mt50">
        <div class="hpa-b-l">
          <img src="/image/apa/apa2.png" alt="Face ID and Facial Recognition" />
        </div>
        <div class="hpa-b-r">
          <div class="plr30">
            <div class="supports supports-box">Supports</div>
            <h3 class="soc-platforms-black h3-title mt50">SoC platforms</h3>
            <p class="p-text hpa-b-r-text">TI (TDA4 etc)</p>
            <p class="p-text hpa-b-r-text">
              Qualcomm (SA8155P, SA8295P, SA8775P, SA8620P, SA8650P etc)
            </p>
            <h3 class="operating-systems-black h3-title mt20">
              Operating Systems
            </h3>
            <p class="p-text hpa-b-r-text">Linux, QNX.</p>
            <h3 class="camera-locations-black h3-title mt20">
              Camera locations
            </h3>
            <p class="p-text hpa-b-r-text">
              Around the body, at the front, rear, left and right.
            </p>
            <h3 class="sensors-black h3-title mt20">Sensors</h3>
            <p class="p-text hpa-b-r-text">Support RGB sensors from 1M up.</p>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="hpa-top mt30">
          <div class="hpa-t-l">
            <p class="introduce">16+ objects sensing</p>
            <div class="objects-tree">
              <div class="static objects">
                <h4>Static objects</h4>
                <p>columns</p>
                <p>construction cones</p>
                <p>car locks</p>
                <p>no-parking</p>
                <p>water barriers</p>
                <p>guardrails</p>
                <p>car wheel stopper</p>
                <p>bumps</p>
                <p>warning signs</p>
                <p>stone blocks</p>
                <p>…</p>
              </div>
              <div class="dynamic objects">
                <h4>Dynamic objects</h4>
                <p>vehicles</p>
                <p>pedestrians</p>
                <p>bicycles/motorcycles</p>
                <p>baby carriers</p>
                <p>shopping carts</p>
                <p>pets</p>
                <p>…</p>
              </div>
            </div>
          </div>
          <div class="hpa-t-r">
            <img src="/image/apa/16.png" alt="Face ID and Facial Recognition" />
          </div>
        </div>
      </div>
    </div>
    <div id="sentry-mode" class="sentry-mode-warpper mt80">
      <div class="sentry-mode-bg"></div>
      <div class="content pt80">
        <h2 class="sentry-mode-title h2-title">Sentry Mode</h2>
        <p class="p-text mt30">
          ArcSoft Sentry Mode is an AVM camera based visual solution to detect
          suspicious behaviors that may cause damages to the cars that are
          parked. Sentry Mode runs on a single IVI SoC, reusing the existing
          computation resources and AVM cameras.
        </p>
        <p class="p-text">
          Sentry mode is mainly divided into three stages: monitoring, alerting,
          and tracing.
        </p>

        <p class="p-text right-white">
          Monitoring: After turning off the engine, if Sentry Mode is activated.
          In standby state, the 4 AVM cameras continuously monitor risks around
          the vehicle.
        </p>
        <p class="p-text right-white">
          Alerting: If Sentry Mode detects a risk, such as someone wandering
          around the car in a very close distance, Sentry Mode switches to Alert
          state, the headlights flash, and the touchscreen displays a message
          indicating that the camera is recording.
        </p>
        <p class="p-text right-white">
          Tracing: After the owner gets into the car, he/she can review the
          recorded video to trace the time and cause of the alert.
        </p>
        <p class="p-text">
          ArcSoft Sentry Mode solution can be activated with different sensing
          options and has the advantages of high accuracy and low power
          consumption. It supports pedestrian detection, automobile detection,
          bicycle and motorized bike detection, and detection of various objects
          such as shopping carts.
        </p>

        <div class="sentry-mode-supports content">
          <div class="l-supports-bg">
            <img
              src="/image/apa/apa3.png"
              alt="Face ID and Facial Recognition"
            />
          </div>
          <div class="r-supports">
            <div class="supports supports-box">Supports</div>
            <h3 class="soc-platforms-black h3-title mt50">SoC platforms</h3>
            <p class="p-text r-supports-text">
              Qualcomm (SA8155P, SA8295P, SA8775P, SM8475, SM7325 etc)
            </p>
            <p class="p-text r-supports-text">
              MTK (MT8666, MT2712, MT8678 etc)
            </p>
            <p class="p-text r-supports-text">Siengine (SE1000 etc)</p>
            <h3 class="camera-locations-black h3-title mt20">
              Camera locations
            </h3>
            <p class="p-text r-supports-text">Reuse AVM cameras.</p>
            <h3 class="sensors-black h3-title mt20">Sensors</h3>
            <p class="p-text r-supports-text">
              Support RGB sensors from 1M up.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div id="smart-trunk" class="smart-trunk-warpper pt80">
      <div class="smart-trunk-bg"></div>
      <div class="content">
        <h2 class="smart-trunk-title h2-title">Smart Trunk</h2>
        <p class="p-text mt30">
          As the appearance of cars becomes more diverse, it is becoming
          increasingly difficult for users to quickly find the On/Off button for
          the trunk. With ArcSoft Smart Trunk, you can simply swipe your leg in
          front of the rearview camera once, and the trunk will be opened for
          you automatically. At the same time, you don't need to touch the
          vehicle surface covered with dust or dirt.
        </p>
        <p class="p-text">
          Sometimes it can be difficult to open the trunk with the key after
          leaving the supermarket if you're carrying a child or multiple
          shopping bags in both hands. ArcSoft Smart Trunk can also help you for
          that.
        </p>
        <div class="smart-trunk-supports-box mt80">
          <div class="supports supports-box">Supports</div>
          <h3 class="soc-platforms-black h3-title mt50">SoC platforms</h3>
          <p class="p-text">
            Qualcomm (SA8155P, SA8295P, SA8775P, SM8475, SM7325 etc)
          </p>
          <p class="p-text">MTK (MT8666, MT2712, MT8678 etc)</p>
          <p class="p-text">Siengine (SE1000 etc)</p>

          <h3 class="camera-locations-black h3-title mt20">Camera locations</h3>
          <p class="p-text">Rearview camera</p>
          <h3 class="sensors-black h3-title mt20">Sensors</h3>
          <p class="p-text">Support RGB sensors from 1M up.</p>

          <img
            class="smart-trunk-supports-bg"
            src="/image/apa/apa.png"
            alt="Occupant Safety System (OSS)"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import ImageWithPlaceholder from '@/components/ImageWithPlaceholder.vue';
export default {
  name: 'Apa',
  components: {
    ImageWithPlaceholder,
  },
  metaInfo: {
    title:
      'Auto Parking Assist (APA) Solutions: AVM, APA, HPA, Sentry Mode, and Smart Trunk',
    meta: [
      {
        name: 'description',
        content: `ArcSoft's comprehensive Auto Parking Assist (APA) solutions include Around View Monitoring (AVM), Auto-Parking Assist (APA), Home-zone Parking Assist (HPA), Sentry Mode, and Smart Trunk technology. These cutting-edge systems offer seamless parking experiences, enhanced vehicle security, and intelligent access for greater convenience in modern vehicles`,
      },
      {
        name: 'keywords',
        content: `Auto Parking Assist, APA solutions, Around View Monitoring, AVM, Home-zone Parking Assist, HPA, Sentry Mode, Smart Trunk, automotive parking technology, intelligent parking systems, vehicle security`, // 关键词
      },
    ],
  },
};
</script>
<style lang="less" scoped>
.auto-parking-assist-warpper {
  position: relative;
  background-color: #fff;
  // padding-top: 1px;
  .operating-systems-white {
    color: #fff;
    background: url('/image/apa/system1.svg') no-repeat;
    background-size: 24px 24px;
  }
  .operating-systems-black {
    color: #222;
    background: url('/image/apa/system.svg') no-repeat;
    background-size: 24px 24px;
  }
  .soc-platforms-white {
    color: #fff;
    background: url('/image/apa/platform.svg') no-repeat;
    background-size: 24px 24px;
  }
  .camera-locations-black {
    color: #222;
    background: url('/image/apa/cameralocation.svg') no-repeat;
    background-size: 24px 24px;
  }
  .camera-locations-white {
    background: url(/image/apa/cameralocation1.svg) no-repeat;
    color: #fff;
    background-size: 24px 24px;
  }
  .sensors-black {
    color: #222222;
    background: url('/image/apa/sensors.svg') no-repeat;
    background-size: 24px 24px;
  }
  .sensors-white {
    color: #fff;
    background: url('/image/apa/sensors1.svg') no-repeat;
    background-size: 24px 24px;
  }
  .soc-platforms-black {
    color: #222;
    background: url('/image/apa/platform1.svg') no-repeat;
    background-size: 24px 24px;
  }

  .p-text {
    line-height: 18px;
    padding: 0 32px;
    margin: 8px 0;
  }
  .right-white {
    padding-left: 52px;
    background: url('/image/apa/arrow.svg') no-repeat 37px 5px;
  }
  .right {
    padding-left: 52px;
    background: url('/image/apa/arrow1.svg') no-repeat 37px 5px;
  }
  .h2-title::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 40px;
    background: #e83021;
    vertical-align: bottom;
    margin-right: 24px;
  }
  .h3-title {
    height: 24px;
    line-height: 24px;
    font-size: 18px;
    text-indent: 32px;
    font-weight: normal;
    margin-bottom: 10px;
  }
  .supports-box {
    width: 109px;
    height: 41px;
    background-color: #e83021;
    border-radius: 2px;
    color: #fff;
    font-size: 18px;
    line-height: 41px;
    text-align: center;
  }
  .content {
    width: 1000px;
    box-sizing: border-box;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    padding-top: 0.1px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .banner {
    width: 100%;
    position: relative;
    .auto-parking-assist-box {
      width: 1000px;
      margin: 0 auto;
      position: absolute;
      bottom: -60px;
      left: calc(50% - 500px);
      z-index: 5;
      .auto-parking-assist-title {
        font-weight: normal;
        font-size: 54px;
        color: #fff;
        text-align: center;
      }
      .item-list {
        margin-top: 246px;
        height: 120px;
        display: inline-flex;
        box-sizing: border-box;
        background: linear-gradient(to bottom, #eaeaeb, #fff);
        padding: initial;
        a {
          font-size: 20px;
          width: 200px;
          list-style: none;
          border: 1px solid #d9d9d9;
          border-left: initial;
          box-sizing: border-box;
          padding: 15px 25px;
          position: relative;
          span {
            display: inline-block;
            width: 150px;
          }
          &:hover::after {
            display: block;
          }
          &:hover {
            color: #e83021;
          }
        }
        a:first-child {
          border-left: 1px solid #d9d9d9;
        }
        .avm {
          background: url(/image/apa/avm.svg) no-repeat 87% 15px e('/') 22px,
            url(/image/apa/go.svg) no-repeat 25px 80px e('/') 22px;
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: none;
            background: url(/image/apa/avm-action.svg) no-repeat 87% 15px e('/')
                22px,
              url(/image/apa/go-action.svg) no-repeat 25px 80px e('/') 22px;
          }
        }
        .apa {
          background: url(/image/apa/apa.svg) no-repeat 87% 18px e('/') 22px,
            url(/image/apa/go.svg) no-repeat 25px 80px e('/') 22px;
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: none;
            background: url(/image/apa/apa-action.svg) no-repeat 87% 18px e('/')
                22px,
              url(/image/apa/go-action.svg) no-repeat 25px 80px e('/') 22px;
          }
        }
        .hpa {
          background: url(/image/apa/hpa.svg) no-repeat 87% 18px e('/') 22px,
            url(/image/apa/go.svg) no-repeat 25px 80px e('/') 22px;
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: none;
            background: url(/image/apa/hpa-action.svg) no-repeat 87% 18px e('/')
                22px,
              url(/image/apa/go-action.svg) no-repeat 25px 80px e('/') 22px;
          }
        }
        .sentry-mode {
          background: url(/image/apa/sentry.svg) no-repeat 87% 18px e('/') 22px,
            url(/image/apa/go.svg) no-repeat 25px 80px e('/') 22px;
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: none;
            background: url(/image/apa/sentry-action.svg) no-repeat 87% 18px
                e('/') 22px,
              url(/image/apa/go-action.svg) no-repeat 25px 80px e('/') 22px;
          }
        }
        .smart-trunk {
          background: url(/image/apa/trunk.svg) no-repeat 87% 16px e('/') 22px,
            url(/image/apa/go.svg) no-repeat 25px 80px e('/') 22px;
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: none;
            background: url(/image/apa/trunk-action.svg) no-repeat 87% 16px
                e('/') 22px,
              url(/image/apa/go-action.svg) no-repeat 25px 80px e('/') 22px;
          }
        }
      }
    }
  }
  .avm-warpper {
    .avm-title {
      color: #222222;
      font-size: 40px;
      font-weight: normal;
    }
    .avm-text {
      margin-bottom: 20px;
    }
  }
  .avm-supports-warpper {
    background: #222222 url(/image/apa/apa1.png) no-repeat 80% center;
    .avm-supports-bg {
      height: 442px;
      color: #fff;
      .supports {
        position: absolute;
        left: 20px;
        top: -20px;
      }
    }
  }
  .apa-warpper {
    background-color: #fff;
    position: relative;
    .apa-title {
      font-size: 40px;
      font-weight: normal;
      color: #222;
    }
    .apa-text {
      color: #222;
    }
    .parking-positions {
      font-size: 18px;
      color: #222;
      margin: 16px 0;
      font-weight: 500;
    }
    .apa-list {
      width: 960px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    .apa-supports-warpper {
      background-color: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(10px);
      padding: 0 24px;
      position: relative;
      box-sizing: border-box;
      border: solid 1px #d9d9d9;
      .supports {
        position: absolute;
        top: -20px;
        left: -1px;
      }
    }
    .apa-bg {
      position: absolute;
      bottom: 80px;
      width: 100%;
      height: 444px;
      box-sizing: border-box;
      background: #222 url('/image/apa/apa6.png') no-repeat center center;
    }
  }
  .hpa-warpper {
    .hpa-title {
      font-size: 40px;
      font-weight: normal;
      color: #222;
      margin-top: initial;
    }
    .hpa-top {
      display: inline-flex;
      justify-content: space-between;
      position: relative;
      width: 100%;
      .hpa-t-l {
        flex: 1;
        .introduce {
          font-size: 18px;
          color: #222;
          font-weight: 500;
          margin-bottom: 16px;
        }
        .objects-tree {
          display: flex;
          color: #222;
          .objects {
            font-size: 14px;
            margin-right: 20px;
            h4 {
              text-indent: 18px;
              line-height: 24px;
              font-weight: 500;
              height: 24px;
              background: url('/image/apa/dot.svg') no-repeat 0 center;
            }
            p {
              text-indent: 18px;
              margin-top: 5px;
            }
          }
        }
      }
      .hpa-t-r {
        width: 696px;
        height: 355px;
        position: absolute;
        right: -96px;

        img {
          width: 100%;
        }
      }
    }
    .hpa-bottom {
      display: flex;
      height: 396px;
      margin: 0 auto;
      .hpa-b-l {
        background-color: #f2faff;
        width: 50%;
        height: 100%;
        text-align: right;
        position: relative;
        top: -20px;
        img {
          height: 100%;
        }
      }
      .hpa-b-r {
        flex: 1;
        height: 376px;
        background-color: #fafafa;
        position: relative;
        .supports {
          position: absolute;
          left: 30px;
          top: -20px;
        }
        .hpa-b-r-text {
          color: #222;
        }
      }
    }
  }
  .sentry-mode-warpper {
    position: relative;
    .sentry-mode-bg {
      width: 100%;
      height: 581px;
      background-color: #222222;
      position: absolute;
    }
    .sentry-mode-title {
      color: #fff;
      font-size: 40px;
      font-weight: normal;
    }
    .p-text {
      color: #fff;
    }
    .sentry-mode-supports {
      margin-top: 60px;
      height: 376px;
      position: relative;
      padding: 30px 0;
      box-sizing: border-box;
      .l-supports-bg {
        z-index: 1;
        position: absolute;
        top: -20px;
        left: -160px;
        height: calc(100% + 20px);
        img {
          height: 100%;
        }
      }
      .r-supports {
        background-color: #fafafa;
        z-index: 2;
        position: absolute;
        top: 0;
        right: -120px;
        width: 640px;
        height: 376px;
        padding: 0 20px;
        box-sizing: border-box;
        .supports {
          position: absolute;
          left: 20px;
          top: -20px;
        }
        .r-supports-text {
          color: #222;
        }
      }
    }
  }
  .smart-trunk-warpper {
    .smart-trunk-title {
      color: #222;
      font-size: 40px;
      font-weight: normal;
    }
    .smart-trunk-supports-box {
      width: 640px;

      padding: 0 26px;
      padding-bottom: 30px;
      border: solid 1px #d9d9d9;
      position: relative;
      box-sizing: border-box;
      .supports {
        position: absolute;
        left: -1px;
        top: -20px;
      }
      .smart-trunk-supports-bg {
        position: absolute;
        right: -319px;
        bottom: 0;
        width: 440px;
        height: 376px;
        img {
          width: 100%;
        }
      }
    }
  }
}

.plr30 {
  padding: 0 30px;
}
</style>
